<template>
    <el-dialog :title="title" :close-on-click-modal="false" :fullscreen="true" append-to-body :visible.sync="visible"
               class="collectForm">
        <div class="formBox">
            <div v-loading="loading">
                <el-descriptions title="基本信息"></el-descriptions>
                <el-form size="small" :model="inputForm" ref="inputForm" :rules="dataRule"
                         label-width="100px" :disabled="method==='view'">
                    <el-form-item label="模板名称：" prop="archivesBasicName">
                        <el-input v-model.trim="inputForm.archivesBasicName" maxlength="50" placeholder="请输入元数据模板名称（限50字）"
                                  clearable></el-input>
                    </el-form-item>
                    <el-form-item label="档案类型：" prop="archivesBasicDataType">
                        <el-select v-model="inputForm.archivesBasicDataType" placeholder="请选择" clearable class="w100i" :disabled="method!='add'">
                            <el-option
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                    v-for="item in $dictUtils.getDictList('archivesBasicDataType')">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="父级模板名称" prop="parentId">
                        <SelectTree v-if="visible"
                                    ref="menuParentTree"
                                    :props="defaultProps"
                                    :data="templateList"
                                    :value="inputForm.parentId"
                                    :clearable="true"
                                    :accordion="true"
                                    @getValue="(value) => {inputForm.parentId=value}"/>
                    </el-form-item>
                    <el-form-item label="简介：" prop="remakes">
                        <el-input v-model.trim="inputForm.remakes" type="textarea" :rows="2" maxlength="200"
                                  placeholder="请输入元数据模板简介（限200字）" clearable></el-input>
                    </el-form-item>
                    <el-descriptions title="元数据管理"></el-descriptions>
                    <div class="text_left">
                        <div class="dataRow tableHeaderBox">
                            <div class="p_all1 b_d"><span class="redColor">*</span>元数据名称</div>
                            <div class="p_all1 b_r b_t b_b">别名</div>
                            <div class="p_all1 b_r b_t b_b">元数据类型</div>
                            <div class="p_all1 b_r b_t b_b"><span class="redColor">*</span>是否必填</div>
                            <div class="p_all1 b_r b_t b_b"><span class="redColor">*</span>查询条件</div>
                            <div class="p_all1 b_r b_t b_b"><span class="redColor">*</span>列表展示</div>
                            <div class="p_all1 b_r b_t b_b">时间格式</div>
                            <div class="p_all1 b_r b_t b_b">最小值</div>
                            <div class="p_all1 b_r b_t b_b">最大值</div>
                            <div class="p_all1 b_r b_t b_b">默认值</div>
                            <div class="p_all1 b_r b_t b_b">元数据名称字体</div>
                            <div class="p_all1 b_r b_t b_b">元数据名称颜色</div>
                            <div class="p_all1 b_r b_t b_b" v-if="method != 'view'">操作</div>
                        </div>
                        <draggable v-model="inputForm.archivesBasicDataGroup" @update="moveList">
                            <div v-for="(item, index) in inputForm.archivesBasicDataGroup" :key="index"
                                 class="dataRow infoRow" v-loading="tableLoading">
                                <div class="b_r b_b b_l">
                                    <el-form-item :prop="`archivesBasicDataGroup.${index}.archivesBasicId`"
                                                  :rules="[{required: true, message: '名称不能为空', trigger: 'change'}]"
                                                  label-width="0">
                                        <el-select v-model="item.archivesBasicId" placeholder="请选择"
                                                   v-if="!tableLoading" filterable @change="archivesBasicIdChange(item)"
                                                   :disabled="item.required == 1">
                                            <el-option v-for="item in archivesBasicOpt" :key="item.archivesBasicId"
                                                       :label="item.basicName"
                                                       :value="item.archivesBasicId" :disabled="item.disabled"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                                <div class="b_r b_b">
                                    <el-form-item :prop="`archivesBasicDataGroup.${index}.forName`" label-width="0">
                                        <el-input size="small" v-model="item.forName" placeholder="限10字"
                                                  maxlength="10" clearable></el-input>
                                    </el-form-item>
                                </div>
                                <div class="b_r b_b">
                                    <el-form-item :prop="`archivesBasicDataGroup.${index}.archivesBasicType`"
                                                  label-width="0">
                                        {{item.archivesBasicType}}
                                    </el-form-item>
                                </div>
                                <div class="b_r b_b">
                                    <el-form-item :prop="`archivesBasicDataGroup.${index}.dataRequired`" label-width="0">
                                        <el-switch v-model="item.dataRequired"
                                                   active-value="0" inactive-value="1"
                                        ></el-switch>
                                    </el-form-item>
                                </div>
                                <div class="b_r b_b">
                                    <el-form-item :prop="`archivesBasicDataGroup.${index}.dataWhere`" label-width="0">
                                        <el-switch v-model="item.dataWhere"
                                                   active-value="0" inactive-value="1" :disabled="item.required == 1"
                                        ></el-switch>
                                    </el-form-item>
                                </div>
                                <div class="b_r b_b">
                                    <el-form-item :prop="`archivesBasicDataGroup.${index}.dataSelect`" label-width="0">
                                        <el-switch v-model="item.dataSelect"
                                                   active-value="0" inactive-value="1" :disabled="item.required == 1"
                                        ></el-switch>
                                    </el-form-item>
                                </div>
                                <div class="b_r b_b">
                                    <el-form-item :prop="`archivesBasicDataGroup.${index}.dataFormat`" label-width="0">
                                        <el-select v-model="item.dataFormat" placeholder="请选择" clearable
                                                   :disabled="item.archivesBasicType != '时间'"
                                                   @change="dataFormatChange(item)">
                                            <el-option label="YYYY-MM-DD hh:mm:ss" value="yyyy-MM-dd HH:mm:ss"></el-option>
                                            <el-option label="YYYY-MM-DD" value="yyyy-MM-dd"></el-option>
                                            <el-option label="YYYYMMDD" value="yyyyMMdd"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                                <div class="b_r b_b">
                                    <el-form-item :prop="`archivesBasicDataGroup.${index}.minLength`" label-width="0">
                                        <el-input v-model="item.minLength"
                                                  @input="getNumType(item, 'minLength')"
                                                  @blur="minLengthChange(item)"
                                                  maxlength="11" clearable v-if="item.archivesBasicType != '时间'"
                                                  :disabled="!item.archivesBasicId"></el-input>
                                        <el-date-picker
                                                v-model="item.minLength" value-format="yyyy-MM-dd HH:mm:ss"
                                                format="yyyy-MM-dd HH:mm:ss"
                                                type="datetime" clearable @blur="timeChange(item, 'minLength')"
                                                v-if="item.dataFormat == 'yyyy-MM-dd HH:mm:ss'" class="w100i">
                                        </el-date-picker>
                                        <el-date-picker
                                                v-model="item.minLength" value-format="yyyy-MM-dd HH:mm:ss"
                                                format="yyyy-MM-dd"
                                                type="date" clearable @blur="timeChange(item, 'minLength')"
                                                v-if="item.dataFormat == 'yyyy-MM-dd'"
                                                class="w100i">
                                        </el-date-picker>
                                        <el-date-picker
                                                v-model="item.minLength" value-format="yyyy-MM-dd HH:mm:ss"
                                                format="yyyyMMdd"
                                                type="date" clearable @blur="timeChange(item, 'minLength')"
                                                v-if="item.dataFormat == 'yyyyMMdd'"
                                                class="w100i">
                                        </el-date-picker>
                                    </el-form-item>
                                </div>
                                <div class="b_r b_b">
                                    <el-form-item :prop="`archivesBasicDataGroup.${index}.maxLength`" label-width="0">
                                        <el-input v-model="item.maxLength"
                                                  @input="getNumType(item, 'maxLength')"
                                                  @blur="maxLengthChange(item)"
                                                  maxlength="11" clearable v-if="item.archivesBasicType != '时间'"
                                                  :disabled="!item.archivesBasicId"></el-input>
                                        <el-date-picker
                                                v-model="item.maxLength" value-format="yyyy-MM-dd HH:mm:ss"
                                                format="yyyy-MM-dd HH:mm:ss"
                                                type="datetime" clearable @blur="timeChange(item, 'maxLength')"
                                                v-if="item.dataFormat == 'yyyy-MM-dd HH:mm:ss'" class="w100i">
                                        </el-date-picker>
                                        <el-date-picker
                                                v-model="item.maxLength" value-format="yyyy-MM-dd HH:mm:ss"
                                                format="yyyy-MM-dd"
                                                type="date" clearable @blur="timeChange(item, 'maxLength')"
                                                v-if="item.dataFormat == 'yyyy-MM-dd'"
                                                class="w100i">
                                        </el-date-picker>
                                        <el-date-picker
                                                v-model="item.maxLength" value-format="yyyy-MM-dd HH:mm:ss"
                                                format="yyyyMMdd"
                                                type="date" clearable @blur="timeChange(item, 'maxLength')"
                                                v-if="item.dataFormat == 'yyyyMMdd'"
                                                class="w100i">
                                        </el-date-picker>
                                    </el-form-item>
                                </div>
                                <div class="b_r b_b">
                                    <el-form-item :prop="`archivesBasicDataGroup.${index}.defaultValue`" label-width="0"
                                                  :rules="[{validator: validator.isEmoji, trigger: 'blur'}, {validator: validatorDefaultValue, trigger: 'blur'}]">
                                        <el-input size="small" v-model="item.defaultValue"
                                                  :maxlength="item.maxLength ? item.maxLength : 300" clearable
                                                  v-if="item.archivesBasicType == '字符'"></el-input>
                                        <el-input size="small" v-model="item.defaultValue"
                                                  @input="getNumType(item, 'defaultValue')"
                                                  maxlength="11" clearable
                                                  v-if="item.archivesBasicType == '数值' || item.archivesBasicType == '浮点数'"></el-input>
                                        <el-date-picker
                                                v-model="item.defaultValue" value-format="yyyy-MM-dd HH:mm:ss"
                                                format="yyyy-MM-dd HH:mm:ss"
                                                type="datetime" clearable @blur="timeChange(item, 'defaultValue')"
                                                v-if="item.dataFormat == 'yyyy-MM-dd HH:mm:ss'" class="w100i">
                                        </el-date-picker>
                                        <el-date-picker
                                                v-model="item.defaultValue" value-format="yyyy-MM-dd HH:mm:ss"
                                                format="yyyy-MM-dd"
                                                type="date" clearable @blur="timeChange(item, 'defaultValue')"
                                                v-if="item.dataFormat == 'yyyy-MM-dd'"
                                                class="w100i">
                                        </el-date-picker>
                                        <el-date-picker
                                                v-model="item.defaultValue" value-format="yyyy-MM-dd HH:mm:ss"
                                                format="yyyyMMdd"
                                                type="date" clearable @blur="timeChange(item, 'defaultValue')"
                                                v-if="item.dataFormat == 'yyyyMMdd'"
                                                class="w100i">
                                        </el-date-picker>
                                    </el-form-item>
                                </div>
                                <div class="b_r b_b">
                                    <el-form-item :prop="`archivesBasicDataGroup.${index}.fontSize`" label-width="0">
                                        <el-select v-model="item.fontSize" placeholder="请选择" clearable>
                                            <el-option label="小号" value="12"></el-option>
                                            <el-option label="中号" value="14"></el-option>
                                            <el-option label="大号" value="16"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                                <div class="b_r b_b">
                                    <el-form-item :prop="`archivesBasicDataGroup.${index}.fontColor`" label-width="0">
                                        <el-select v-model="item.fontColor" placeholder="请选择" clearable>
                                            <el-option label="红色" value="#BC2124"></el-option>
                                            <el-option label="蓝色" value="#0000FF"></el-option>
                                            <el-option label="黑色" value="#000000"></el-option>
                                            <el-option label="浅灰" value="#999999"></el-option>
                                            <el-option label="深灰" value="#666666"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                                <div class="b_r b_b" v-if="method != 'view'">
                                    <el-form-item label-width="0">
                                        <el-button type="text" size="mini"
                                                   v-if="hasPermission('metadata:templateList:requiredDelete') || item.required != 1"
                                                   @click="delMetadataRow(index)">删除
                                        </el-button>
                                    </el-form-item>
                                </div>
                            </div>
                        </draggable>
                        <div class="text_center p_all2">
                            <el-button size="small" type="primary" icon="el-icon-plus" @click="addMetadataRow()" v-if="method != 'view'"
                                       :disabled="inputForm.archivesBasicDataGroup.length >= 99">添加元数据
                            </el-button>
                        </div>
                    </div>
                </el-form>
            </div>
        </div>
        <div class="text_center p_t2">
            <el-button size="small" @click="visible = false">{{method == 'view' ? '关闭' : '取消'}}</el-button>
            <el-button size="small" type="primary" @click="doSubmit()" v-if="method != 'view'"
                       v-noMoreClick>保存
            </el-button>
        </div>
    </el-dialog>
</template>

<script>
    import SelectTree from '@/components/treeSelect/treeSelect.vue'
    import draggable from "vuedraggable";

    export default {
        components: {
            SelectTree, draggable
        },
        data() {
            return {
                id: '',
                title: '',
                visible: false,
                method: '',
                loading: false,
                defaultProps: {
                    value: 'id',
                    label: 'archivesBasicName',
                    children: 'children',
                },
                inputForm: {
                    id: '',
                    parentId: '0',
                    archivesBasicName: '',
                    archivesBasicDataType: '',
                    remakes: '',
                    archivesBasicDataGroup: []
                },
                metadataRow: {
                    archivesBasicId: '',
                    archivesBasicType: '',
                    dataFormat: '',
                    dataRequired: '0',
                    dataSelect: '0',
                    dataWhere: '0',
                    forName: '',
                    defaultValue: '',
                    fontSize: '',
                    fontColor: '',
                    minLength: '',
                    maxLength: '',
                    sorts: '',
                },
                templateList: [],
                archivesBasicOpt: [],
                selArchives: [],
                tableLoading: false,
                dataRule: {
                    archivesBasicName: [
                        {required: true, message: '名称不能为空', trigger: 'blur'},
                        {validator: this.validator.isEmoji, trigger: 'blur'}
                    ],
                    archivesBasicDataType: [
                        {required: true, message: '档案类型不能为空', trigger: 'change'}
                    ],
                },
            }
        },
        methods: {
            init(row, method) {
                this.loading = false
                this.method = method
                this.inputForm.id = ''
                this.inputForm.parentId = '0'
                if (row) {
                    this.id = row.id
                }
                if (method === 'add') {
                    this.title = '新增元数据模板'
                } else if (method === 'edit') {
                    this.title = '修改元数据模板'
                } else if (method === 'view') {
                    this.title = '查看元数据模板'
                }
                this.visible = true
                this.$nextTick(() => {
                    this.$refs['inputForm'].resetFields()
                    this.getTemplateList()
                })
            },
            // 获取查看信息
            getInfo() {
                this.$axios(this.api.record.basicdataGetById + '/' + this.id).then(data => {
                    if (data.status) {
                        this.inputForm = data.data
                        this.$nextTick(() => {
                            this.filterBasicData()
                        })
                        this.inputForm.archivesBasicDataGroup.map((item, index) => {
                            this.$set(item, 'sorts', index + 1)
                        })
                        this.setDisabledTreeData(this.templateList, this.inputForm.archivesBasicName)
                    }
                })
            },
            // 获取必选元数据
            getList() {
                this.inputForm.archivesBasicDataGroup = []
                this.$axios(this.api.record.selectRequiredArchivesBasic).then((res) => {
                    if (res.status) {
                        this.inputForm.archivesBasicDataGroup = res.data
                        this.inputForm.archivesBasicDataGroup.map((item, index) => {
                            this.$set(item, 'sorts', index + 1)
                            if (item.archivesBasicTypeCode == 'date') {
                                this.$set(item, 'dataFormat', 'yyyyMMdd')
                            }
                        })
                        this.$nextTick(() => {
                            this.filterBasicData()
                        })
                    }
                })
            },
            // 获取元数据名称选项
            getArchivesBasicOpt(type) {
                this.tableLoading = true
                this.$axios(this.api.record.selectAllArchivesBasic).then((res) => {
                    if (res.status) {
                        this.tableLoading = false
                        this.archivesBasicOpt = res.data
                        if (this.method === 'add' && !type) {
                            this.getList()
                        }
                        if (this.method === 'edit' || this.method === 'view') {
                            this.$nextTick(() => {
                                this.getInfo()
                            })
                        }
                    }
                })
            },
            // 设置禁选（不能选择当前模板名称）
            setDisabledTreeData(data, name) {
                data.map((item) => {
                    if (item.archivesBasicName == name) {
                        this.$set(item, "disabled", true);
                    }
                    let isFatherNode = item.children && item.children.length > 0;
                    isFatherNode && this.setDisabledTreeData(item.children, name);
                    let isHadDisabledChild = isFatherNode && item.children.some((it) => it.archivesBasicName == name);
                    if (isHadDisabledChild) this.$set(item, "disabled", true);
                });
            },
            // 获取模板列表
            getTemplateList() {
                this.$axios(this.api.record.selectArchivesBasicData).then((res) => {
                    if (res.status) {
                        let arr = [];
                        res.data.forEach(i => {
                            if (i.children && i.children.length) {
                                i.children.forEach((j) => {
                                    if (j.children && j.children.length) {
                                        j.children.forEach((k) => {
                                            if (k.children && k.children.length) {
                                                k.children.forEach((l) => {
                                                    if (l.children && l.children.length) {
                                                        delete l.children;
                                                    }
                                                });
                                            }
                                        });
                                    }
                                });
                            }
                            arr.push(i);
                        })
                        this.templateList = arr
                        this.getArchivesBasicOpt()
                    }
                })
            },
            // 时间格式修改
            dataFormatChange(item) {
                this.$set(item, 'minLength', '')
                this.$set(item, 'maxLength', '')
            },
            // 删除
            delMetadataRow(index) {
                this.inputForm.archivesBasicDataGroup.splice(index, 1);
                this.filterBasicData()
            },
            // 增加
            addMetadataRow() {
                let row = JSON.parse(JSON.stringify(this.metadataRow))
                row.sorts = this.inputForm.archivesBasicDataGroup.length + 1
                this.inputForm.archivesBasicDataGroup.push(row)
            },
            // 列表拖拽
            moveList() {
                this.inputForm.archivesBasicDataGroup.map((item, index) => {
                    this.$set(item, 'sorts', index + 1)
                })
            },
            // 元数据名称修改
            archivesBasicIdChange(item) {
                this.$set(item, 'forName', '')
                this.$set(item, 'defaultValue', '')
                this.$set(item, 'fontSize', '')
                this.$set(item, 'fontColor', '')
                this.$set(item, 'minLength', '')
                this.$set(item, 'maxLength', '')
                this.archivesBasicOpt.map(v => {
                    if (v.id == item.archivesBasicId) {
                        this.$set(item, 'archivesBasicType', v.archivesBasicType)
                        this.$set(item, 'basicName', v.basicName)
                    }
                })
                this.$nextTick(() => {
                    if (item.archivesBasicType == '时间') {
                        this.$set(item, 'dataFormat', 'yyyyMMdd')
                    } else {
                        this.$set(item, 'dataFormat', '')
                    }
                    this.filterBasicData()
                })
            },
            // 过滤元数据名称
            filterBasicData() {
                this.selArchives = []
                this.inputForm.archivesBasicDataGroup.map(v => {
                    if (v.archivesBasicId) {
                        this.selArchives.push(v.archivesBasicId)
                    }
                })
                this.archivesBasicOpt.map(item => {
                    this.$set(item, 'disabled', false)
                })
                this.$nextTick(() => {
                    for (let id of this.selArchives) {
                        let itemJson = this.archivesBasicOpt.find((item) => {
                            return item.id === id
                        });
                        this.$set(itemJson, 'disabled', true)
                    }
                })
            },
            // 最小值修改
            minLengthChange(item) {
                let {archivesBasicType, minLength, maxLength} = item
                let maxVal = 99999999
                let regular = /[^\d]/g
                if (item.archivesBasicType == '浮点数') {
                    regular = /[^\d.]/g
                }
                let value = minLength
                if (value) {
                    value = minLength.replace(regular, '')
                }
                if (archivesBasicType == '字符') {
                    maxVal = 300
                }
                if (value) {
                    if (archivesBasicType == '数值' && !/^\+?[1-9][0-9]*$/.test(value) || archivesBasicType == '字符' && !/^\+?[1-9][0-9]*$/.test(value)) {
                        this.$message.warning('请输入正确的数值且大于0')
                        this.$set(item, 'minLength', '')
                    } else if (archivesBasicType == '浮点数' && !this.validate.isPrice(value)) {
                        this.$message.warning('请输入正确的浮点数，浮点数类型最多保留两位小数')
                        this.$set(item, 'minLength', '')
                    } else if (value > maxVal) {
                        this.$message.warning('最小值必须小于等于' + maxVal)
                        this.$set(item, 'minLength', '')
                    } else if (maxLength && value >= maxLength * 1) {
                        this.$message.warning('最小值必须小于最大值')
                        this.$set(item, 'minLength', '')
                    } else {
                        this.$set(item, 'minLength', value)
                    }
                }

            },
            // 最大值修改
            maxLengthChange(item) {
                let {archivesBasicType, minLength, maxLength} = item
                let maxVal = 99999999
                let regular = /[^\d]/g
                if (item.archivesBasicType == '浮点数') {
                    regular = /[^\d.]/g
                }
                let value = maxLength.replace(regular, '')
                if (archivesBasicType == '字符') {
                    maxVal = 300
                }
                if (value) {
                    if (archivesBasicType == '数值' && !/^\+?[1-9][0-9]*$/.test(value) || archivesBasicType == '字符' && !/^\+?[1-9][0-9]*$/.test(value)) {
                        this.$message.warning('请输入正确的数值且大于0')
                        this.$set(item, 'maxLength', '')
                    } else if (archivesBasicType == '浮点数' && !this.validate.isPrice(value)) {
                        this.$message.warning('请输入正确的浮点数，浮点数类型最多保留两位小数')
                        this.$set(item, 'maxLength', '')
                    } else if (value > maxVal) {
                        this.$message.warning('最大值必须小于等于' + maxVal)
                        this.$set(item, 'maxLength', '')
                    } else if (minLength && value <= minLength * 1) {
                        this.$message.warning('最大值必须大于最小值')
                        this.$set(item, 'maxLength', '')
                    } else {
                        this.$set(item, 'maxLength', value)
                    }
                }
            },
            // 最大最小时间修改
            timeChange(item) {
                let minLength = item.minLength, maxLength = item.maxLength
                if (item.dataFormat == 'yyyyMMdd') {
                    minLength = item.minLength ? item.minLength.replace(/^(\d{4})(\d{2})(\d{2})$/, "$1-$2-$3") : '';
                    maxLength = item.maxLength ? item.maxLength.replace(/^(\d{4})(\d{2})(\d{2})$/, "$1-$2-$3") : '';
                }
                if (minLength && new Date(maxLength) <= new Date(minLength)) {
                    this.$message.warning('最大值时间必须大于最小值时间')
                    this.$set(item, 'maxLength', '')
                } else if (maxLength && new Date(minLength) >= new Date(maxLength)) {
                    this.$message.warning('最小值时间必须小于最大值时间')
                    this.$set(item, 'minLength', '')
                }
            },
            // 获取元数据类型设置输入正则
            getNumType(item, type) {
                let {minLength, maxLength} = item
                let maxVal = 99999999
                let regular = /[^\d]/g
                if (item.archivesBasicType == '浮点数') {
                    regular = /[^\d.]/g
                }
                if (item[type]) {
                    this.$set(item, type, item[type].replace(regular, ''))
                }
                if (item[type] && type == 'minLength') {
                    if (minLength >= maxVal) {
                        this.$set(item, 'minLength', maxVal)
                    }
                    if (maxLength && minLength >= maxVal) {
                        this.$set(item, 'maxLength', maxVal)
                        this.$set(item, 'minLength', maxVal - 1)
                    }
                }
                if (item[type] && type == 'maxLength') {
                    if (maxLength >= maxVal) {
                        this.$set(item, 'maxLength', maxVal)
                    }
                }
            },
            // 校验默认值
            validatorDefaultValue(rule, value, callback) {
                let index = parseInt(rule.field.split(".")[1])
                let item = this.inputForm.archivesBasicDataGroup[index]
                if (item.archivesBasicType == '字符') {
                    if (value && item.minLength && value.length < item.minLength) {
                        callback(new Error('长度小于最小值'))
                    } else if (value && item.maxLength && value.length > item.maxLength) {
                        callback(new Error('长度大于最大值'))
                    } else {
                        callback()
                    }
                }
                if (item.archivesBasicType == '数值' || item.archivesBasicType == '浮点数') {
                    if (value && item.minLength && value * 1 < item.minLength * 1) {
                        callback(new Error('不能小于最大值'))
                    } else if (value && item.maxLength && value * 1 > item.maxLength * 1) {
                        callback(new Error('不能大于最大值'))
                    } else {
                        callback()
                    }
                }
                if (item.archivesBasicType == '时间') {
                    let minLength = item.minLength, maxLength = item.maxLength, defaultValue = value
                    if (item.dataFormat == 'yyyyMMdd') {
                        minLength = item.minLength ? item.minLength.replace(/^(\d{4})(\d{2})(\d{2})$/, "$1-$2-$3") : '';
                        maxLength = item.maxLength ? item.maxLength.replace(/^(\d{4})(\d{2})(\d{2})$/, "$1-$2-$3") : '';
                        defaultValue = value ? value.replace(/^(\d{4})(\d{2})(\d{2})$/, "$1-$2-$3") : '';
                    }
                    if (minLength && new Date(defaultValue) < new Date(minLength)) {
                        callback(new Error('不能小于最大值'))
                    } else if (maxLength && new Date(defaultValue) > new Date(maxLength)) {
                        callback(new Error('不能大于最大值'))
                    } else {
                        callback()
                    }
                }
            },
            // 提交
            doSubmit() {
                let param = JSON.parse(JSON.stringify(this.inputForm))
                if (!param.archivesBasicDataGroup.length) {
                    this.$message.error('请增加元数据')
                    return
                }
                this.$refs['inputForm'].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        let api = this.api.record.basicdataSave, type = 'post'
                        if (this.method === 'edit') {  // 修改
                            api = this.api.record.basicdataUpdateById
                            type = 'put'
                        }
                        this.$axios(api, param, type).then(data => {
                            this.loading = false
                            if (data && data.status) {
                                this.$message.success(data.msg)
                                this.visible = false
                                this.$emit('refreshDataList')
                            } else {
                                this.$message.error(data.msg)
                            }
                        })
                    }
                })
            },
        },
    }
</script>

<style scoped>
    .el-form-item--small.el-form-item {
        margin: 18px 8px;
    }

    .dataRow > div {
        font-weight: 500;
        color: #4D4D4D;
        font-size: 12px;
        text-align: center;
        display: inline-block;
        width: 8%;
        white-space: nowrap;
        vertical-align: middle;
    }

    .tableHeaderBox > div {
        background: #F9DFDF;
    }

    .infoRow > div {
        height: 70px;
    }

    .dataRow > div:nth-child(3), .dataRow > div:nth-child(4), .dataRow > div:nth-child(5), .dataRow > div:nth-child(6) {
        width: 7%;
    }

    .dataRow > div:nth-child(11), .dataRow > div:nth-child(12) {
        width: 9%;
    }

    .dataRow > div:nth-child(13) {
        width: 5.5%;
    }
</style>
